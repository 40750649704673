import React, { Fragment, ReactNode } from "react";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";
import Dash from "../icon/Dash";
import { Box, BoxProps } from "../box/Box";

interface Props extends BoxProps {
    withDash?: boolean;
    dashColor?: string;
    aside?: ReactNode;
    content?: ReactNode;
    reverse?: boolean;
    showAsideOnMobile?: boolean;
}

export const Container = styled(Box)<{ reverse?: boolean }>`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr;

    .aside,
    .content,
    .dash {
        padding: ${theme("space.4")}px;
    }

    @media ${theme("mediaQueries.lg")} {
        display: grid;
        grid-template-columns: ${ifProp("reverse", "1fr 7fr 1fr 320px 1fr", "1fr 320px 1fr 7fr 1fr")};
        grid-auto-flow: column;
        .aside {
            grid-column-start: ${ifProp("reverse", 4, 2)};
            grid-column-end: span 1;
        }

        .content,
        .dash {
            grid-column-start: ${ifProp("reverse", 2, 4)};
            grid-column-end: span 1;
        }
    }
`;

export default ({
                    aside,
                    content,
                    showAsideOnMobile = false,
                    withDash = false,
                    dashColor,
                    reverse,
                    ...rest
                }: Props) => (
                    <Container reverse={reverse} {...rest}>
                        {withDash && (
                        <>
                            <Box
                                className="aside"
                                display={showAsideOnMobile ? "block" : ["none", "none", "block"]}
                            />
                            <Box className="dash">
                                <Dash color={dashColor} />
                            </Box>
                        </>
        )}

                        <Box className="aside" display={showAsideOnMobile ? "block" : ["none", "none", "block"]}>
                            {aside}
                        </Box>

                        <Box className="content">{content}</Box>
                    </Container>
);
