import React, { ReactNode } from "react";
import styled from "styled-components";
import { prop, theme } from "styled-tools";
import { Box, BoxProps } from "../box/Box";

export const Container = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr;

    @media ${theme("mediaQueries.sm")} {
        grid-template-columns: repeat(12, 1fr);
        > div {
            grid-column: ${prop("gridColumn", "1/-1")};
        }
    }
`;

interface Props extends BoxProps {
    children: ReactNode;
    gridColumn: string;
}

export default ({ children, px = 2, ...rest }: Props) => (
    <Container px={px} {...rest}>
        <Box>{children}</Box>
    </Container>
);
