import React, { ReactNode } from "react";
import styled from "styled-components";
import { prop, theme } from "styled-tools";
import { useInView } from "react-intersection-observer";
import { Box, BoxProps } from "../box/Box";

const lazyOptions = {
    triggerOnce: true,
    rootMargin: "300px 0px",
};

const Container = styled(Box)`
    background-repeat: no-repeat;
    background-size: auto 70%;
    background-position: center top;

    @media ${theme("mediaQueries.md")} {
        background-size: ${prop("backgroundSize", "cover")};
        background-position: ${prop("backgroundPosition", "center")};
        > div {
            max-width: ${theme("gridMaxWidth")}px;
            margin: auto;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
        }
    }
`;

interface Props extends BoxProps {
    backgroundImage?: string | string[];
    headline: ReactNode;
    contentBgColor?: string;
}

export default ({ backgroundImage, headline, content, contentBgColor = "white", ...rest }: Props) => {
    const [ref, inView] = useInView(lazyOptions);

    let srcUrls: string | string[] | undefined;

    if (inView && backgroundImage) {
        srcUrls = Array.isArray(backgroundImage)
            ? backgroundImage.map((url) => `url("${url}")`)
            : `url("${backgroundImage}")`;
    }

    return (
        <Container ref={ref} pb={7} pt={[12, 7]} px={5} backgroundImage={srcUrls} {...rest}>
            <div>
                <Box my={[4, 7]} p={[2, 2, 2, 5]} gridColumn="2 / span 10">
                    {headline}
                </Box>
                <Box my={[0, 0, 7]} mx={3} p={[5, 5, 5, 8]} bg={contentBgColor} gridColumn="4 / span 8">
                    {content}
                </Box>
            </div>
        </Container>
    );
};
